<template>
  <div class="deposit">
    <div class="depositTitle">
      <!-- <div class="myDeposit">
        <p>我要充值</p>
      </div> -->
    </div>
    <div class="depositContent colorFont">
      <p v-if="isQyInfo == 0">您还未信息上报，请先去信息上报页面进行上报</p>
      <p v-if="isQyInfo == 1">
        <span
          style="color: red; font-size:18px;font-weight:700"
          v-if="isQyBank !== ''"
          >{{ isQyBank | BankSignStatus }}</span
        >
      </p>
      <p v-if="isQyInfo == 8">您的信息上报失败, 请先去信息上报页面重新填写</p>
      <p v-if="isQyInfo == 9">您的信息正在上报，请等待</p>
    </div>
    <div class="bankTips">
        <p class="title" style="font-size:16px">温馨提示：入金时间为早9:00-15:30</p>
        <!-- <p class="desc" style="font-size:18px">
          入金时间为早9:00-15:30
        </p> -->
      </div>
    <div
      class="depositContent"
      v-if="isQyInfo == 1 && (isQyBank == 1 || isQyBank == 13)"
    >
      <div class="bankDetails">
        <p class="desc">
          收款方开户行
          <span>{{ bankDetails.openBankName }}
          <i
            class="fa fa-files-o"
            aria-hidden="true"
            v-clipboard:copy="bankDetails.openBankName"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          ></i></span>
        </p>
        <p class="desc">
          收款方账号<span>{{ bankDetails.bankAccount }}
          <i
            class="fa fa-files-o"
            aria-hidden="true"
            v-clipboard:copy="bankDetails.bankAccount"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          ></i></span>
        </p>
        <p class="desc">
          收款方户名<span>{{ bankDetails.bankAccountName }}
          <i
            class="fa fa-files-o"
            aria-hidden="true"
            v-clipboard:copy="bankDetails.bankAccountName"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          ></i></span>
        </p>
        <!-- <p class="desc">行号：{{bankDetails.openBankNumber}}
          <i class="fa fa-files-o" aria-hidden="true" v-clipboard:copy="bankDetails.openBankNumber" v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
        </p> -->
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        bankId: null
      },
      rules: {
        bankId: [{ required: true, message: "请选择银行", trigger: "change" }]
      },
      infoIdOptions: {},
      bankDetails: {
        bankAccount: "15155101040051327",
        bankAccountName: " 山东交易市场清算所有限公司",
        openBankName: "中国农业银行股份有限公司济南花园路支行",
        openBankNumber: null,
        signFlag: null
      },
      amountFrom: {
        amount: "",
        bankSerNum: ""
      },
      amountRules: {
        amount: [{ required: true, message: "请输入金额", trigger: "change" }],
        bankSerNum: [
          { required: true, message: "请输入银行流水号", trigger: "change" }
        ]
      },
      isQyBank: "", // 是否签约绑卡 0未签约 1已签约 2解约3签约未绑卡
      isQyInfo: 0 // 是否信息上报
    };
  },
  mounted() {
    this.getSignInfo();
    this.getfindTrader();
  },
  methods: {
    // 信息上报状态查询
    getfindTrader() {
      http.postFront(protocolFwd.param_findTrader).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) == 0) {
          if (value) {
            //  1：交易商正常，  9:交易商开户在途  8:交易商开户失败
            this.isQyInfo = value.status;
          } else {
            this.isQyInfo = 0;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 签约信息查询
    getSignInfo() {
      http.postFront(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value) {
            this.isQyBank = value.signFlag;
            console.log(Boolean(this.isQyBank), "xxxxx");
            // this.bankDetails = value;
          } else {
            // this.isQyBank =
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    onCopy() {
      this.$EL_MESSAGE("复制成功");
    },
    onError() {
      this.$EL_MESSAGE("复制失败");
    },
    submitApply() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_fundIn.param.bankId = 1;
          protocolFwd.param_fundIn.param.amount = this.form.amount;
          http.postFront(protocolFwd.param_fundIn).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.deposit {
  // padding: 0 15px;
}
.deposit .myDeposit {
  float: left;
  line-height: 40px;
}
.depositTitle {
  overflow: hidden;
}
.depositTitle .myDeposit p {
  font-size: 16px;
}
.depositContent {
  width: 80%;
}
.colorFont {
  // color: #cf4640;
  color: red;
  font-weight: 700;
  p {
    font-size: 18px !important;
  }
}
.depositContent .tips {
  margin-bottom: 10px;
  color: #9999a2;
}
.bankDetails,
.bankTips {
  line-height: 35px;
}
.bankDetails .desc,
.bankTips .desc {
  // text-indent: 2em;
}
.bankDetails .desc i {
  text-indent: 0.5em;
  font-size: 18px;
  cursor: pointer;
}
.bankDetails {
  display: flex;
  flex-wrap: wrap;
  color: #9999a2;
  margin-bottom: 30px;
}
.bankDetails span{
  // width: 50%;
  display: block;
  padding: 10px;
  background: #F3F3F3;
  border-radius: 8px;
}
.bankTips {
  // color: #1989fa;
  margin-top: 10px;
}
.desc {
  width: 600px;
  color: #606266;
  font-weight: 500;
}
.title {
  font-weight: 700;
  color: red;
}
</style>
